<template>

    <Title title = 'fpxreceiptpage.title'/>

    <div class = 'md:w-2/3 lg:w-2/5 mx-auto text-center mt-5'>

        <div class="text-left">{{ $t('fpxreceiptpage.refno') }}</div>

        <input ref="refno" class='my-2 bg-white rounded p-2 shadow focus:outline-none text-gray-700 w-full' 
            v-model="refno" placeholder="E.g.  QREWEB000011112222AAAA" />

        <button type="button" :disabled="loading" @click = "downloadReceipt" class = 'm-1 bg-blue-500 hover:bg-blue-700 rounded p-2 px-4 text-white text-sm'>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
            </svg>
            <span class = 'hidden sm:inline-block ml-2'>{{ $t('fpxreceiptpage.download') }}</span>
        </button>

        <div v-if="status == 'PENDING'" class="text-red-600 p-5 font-bold">{{ $t('fpxreceiptpage.pending') }}</div>
        <div v-if="status == 'NOT FOUND'" class="text-red-600 p-5 font-bold">{{ $t('fpxreceiptpage.notfound') }}</div>
        <div v-if="error" class="text-red-600 p-5 font-bold">{{ $t('fpxreceiptpage.error') }}</div>

    </div>

</template>

<script>
    import Title from '@/components/Title'
    import axios from 'axios'
    import config from '@/config'

    export default {
        name: "FpxReceipt",
        data: () => {
            return {
                refno: "",
                status: "",
                error: false,
                loading: false,
            }
        },
        mounted: function () {

            
        },
        components: {
            Title
        },
        computed: {
            
        },
        methods: {
            downloadReceipt() {

                if (this.refno == "") {
                    alert("Please enter reference no.");
                }

                this.loading = true;

                axios.get(config.FPX_STATUS_URL + "/" + this.refno)
                    .then(res => {

                        this.status = res.data.msg;

                        if (this.status == "SUCCESS") {

                            axios
                                .get(config.FPX_RECEIPT_URL + "?refno=" + this.refno, {
                                    responseType: 'blob',
                                })
                                .then(response => {
                                    
                                    if (response.data.size == 0) {
                                        this.error = true;
                                        this.loading = false;
                                        return;
                                    }

                                    this.error = false;

                                    var link = document.createElement('a');
                                    var url = window.URL.createObjectURL(response.data);
                                    link.href = url;
                                    link.download = 'RECEIPT_' + this.refno + '.pdf';
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                    window.URL.revokeObjectURL(url);

                                    this.loading = false;
                                })
                                .catch(error => {
                                    console.log(error);
                                    this.error = true;
                                    this.loading = false;
                                });
                            
                        } else {
                            this.loading = false;
                        }

                    })
                    .catch(error => {
                        this.loading = false;
                    })
                
            },
        },
    }
</script>