<template>
  <div class = 'pb-20 align-middle'>
    <img src = "@/assets/logo.png" class = 'object-contain h-36 w-full mt-10'/>

    <Title title = 'homepage.title'/>
    
    <div class = 'relative w-3/4 sm:w-1/4 mx-auto'>
      <div class = 'shadow border rounded py-2 px-3 text-gray-700 bg-white'>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 hidden sm:inline-block text-gray-700 mr-2 cursor-pointer"
          fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
        <input v-model = "titleno" ref = 'titleno' maxlength = "9" type = "text" :placeholder="$t('titleno')" @keypress="textChange($event)"
          class = 'focus:outline-none w-1/2 inline-block'/>
        
        <img src = '@/assets/loading-buffering.gif' class = 'h-7 sm:h-8 inline-block float-right' v-show = 'showspinner'/>
      </div>

      <div v-show = "errormsg!=''" class = 'bg-red-200 rounded-md border-2 p-3 w-full mt-3 absolute'>
        <span class = 'text-red-800'>{{$t(errormsg)}}</span>
        <button @click = "errormsg=''" class = 'float-right'>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
            </svg>
        </button>
      </div>
    </div>

    <div class = 'text-gray-700 mt-4 text-center text-xs sm:text-base'>
      <p class = 'm-auto w-3/4'>{{ $t('homepage.msg1') }} <a class = 'font-bold text-blue-500' href = 'https://jtu.sabah.gov.my/index.cfm?section=Utility&action=Generate' target='_blank'>{{ $t('here') }}</a></p>
      <p class = 'mt-4 w-3/4 m-auto'>{{ $t('homepage.msg2') }}</p>
      <p class = 'mt-4'>{{ $t('homepage.officehour') }}</p>
      <p>8:00 a.m. - 1:00 p.m.</p>
	    <p>2:00 p.m. - 5:00 p.m.</p>
    </div>

  </div>
</template>

<script>

import router from '@/router'
import Title from '@/components/Title'
import axios from 'axios'
import config from '@/config'

export default {
  name: 'Home',
  components:{
    Title
  },
  data:()=>{
    return {
      titleno:"",
      showspinner:false,
      errormsg:"",
    }
  },
  mounted:function(){
    this.$refs.titleno.focus();
  },
  watch:{
    titleno:function(val){
      if (val.length === 9)
      {
        this.showspinner = true
        this.find()
      } 
      else
      {
        this.errormsg = 'homepage.lessthan9char'
        this.showspinner = false;
      } 
    }
  },
  methods:{
    find:function(){
      this.errormsg = ""
      axios.get(config.CHECKTITLE_URL + "?titleno=" + this.titleno)
        .then(res=>{
          if (res.data.status === 'OK')
            router.push({path:'arrears', query:{titleno:this.titleno}})
          else
            this.errormsg = 'homepage.noPI'

          this.showspinner = false
        })
    },
    textChange:function(event){
      let val = event.keyCode ? event.keyCode : event.which;
      if (val < 48 || val > 57){
        event.preventDefault();
      }
    }
  }
}
</script>
